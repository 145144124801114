import React from 'react'
import Layout from '../components/layout'
import FunctionTrackerForm from '../components/Pages/PrivateDining/PrivateDiningForm/FunctionTrackerForm'

const test = () => {
  return (
<Layout>hi hi hi
<FunctionTrackerForm />
</Layout>
  )
}

export default test